<template>
  <Ask ref="ask"
    :canAccept="true"
  >
    <TextArea
      height="180"
      placeholder="Reason for rejection"
      :auto-grow="false"
      :no-resize="true"
      :value="reason"
      @change="change"
    ></TextArea>
  </Ask>
</template>

<script>
import TextArea from '@/components/UI/forms/textarea'

export default {
  props: {
    selected: {
      type: Object,
      default () { return {} }
    }
  },
  components: {
    TextArea
  },
  data () {
    return {
      reason: ''
    }
  },
  methods: {
    change (change) {
      // console.log(change)
      this.reason = change.value
    },
    async open () {
      // const user = this.$store.state.auth.user
      const result = await this.$refs.ask.open(
        'Reject Finding',
        `<p>You are about to reject this Finding file:<ul>${this.selected.acbFindingFile}</ul></p>
         <p>You can add a reason for this rejection that will be viewable by the Auditor.</p>
        `,
        {
          color: "error",
          cancelLabel: 'Cancel',
          acceptLabel: 'Reject',
          acceptColor: 'error',
          reverseActions: true,
          icon: 'mdi-upload',
          width: 460
        }
      )
      return {
        result,
        reason: this.reason
      }
    }
  },
  watch: {
    selected () {
      this.reason = ''
    }
  }
}
</script>
