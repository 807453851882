<template>
  <v-container fluid>
    <Tabs class="">
      <template
        v-for="(tab, i) in tabs"
      >
        <Tab
          :key="i"
          :label="tab.label"
        >
          <TabPanel
            :type="tab.type"
            :cols="tab.cols"
            @add="add"
            @select="select"
          />
        </Tab>
      </template>
    </Tabs>
    <DocumentDrawer
      :open="ddo"
      :title="ddt"
      :width="640"
      :stateless="false"
      @close="ddoClose"
    >
      <template
        v-slot:actionMenu
      >
        <ActionMenu
          :items="actionMenuItems"
        />
      </template>
      <template v-slot:default>
        <Detail
          v-if="selected"
          :user="selected"
          @close="ddoClose"
        />
      </template>
    </DocumentDrawer>
  </v-container>
</template>

<script>
import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'

import Detail from './detail'
import TabPanel from './tab-panel'

import { tabs } from './tab-definitions'

export default {
  components: {
    ActionMenu,
    Detail,
    DocumentDrawer,
    TabPanel
  },
  computed: {
    ddt () {
      return this.selected ? this.selected.name || 'New User' : 'New User'
    },
    actionMenuItems () {
      if (! (this.selected && this.selected._id) ) return null
      return this.selected.enabled
        ? [
            {
              label: 'Disable Account',
              icon: 'mdi-lock',
              action: this.lock
            }
          ]
        : [
            {
              label: 'Enable Account',
              icon: 'mdi-lock-open',
              action: this.unlock
            }
          ]
    }
  },
  methods: {
    add ({ type }) {
      const { User } = this.$FeathersVuex.api
      this.selected = new User({ type })
      this.ddo = true
    },
    ddoClose () {
      this.ddo = false
      this.selected = null
    },
    select (user) {
      this.selected = user
      this.ddo = true
    },
    lock () {
      this.$store.dispatch('users/patch', [this.selected._id, { enabled: false }])
    },
    unlock () {
      this.$store.dispatch('users/patch', [this.selected._id, { enabled: true }])
    }
  },
  data () {
    return {
      ddo: false,
      tabs: tabs,
      selected: null
    }
  }
}
</script>
