<template>
  <v-container fluid>
    <PageTitle />
    <DataTable
      :cols="cols"
      :data="items"
      @selectOne="select"
    />
    <DocumentDrawer
      :open="ddo"
      :title="ddt"
      :width="640"
      :stateless="false"
      @close="ddo=false"
    >
      <template v-slot:default>
        <Detail
          v-if="selected"
          :item="selected"
          @close="ddo=false"
        />
      </template>
    </DocumentDrawer>
  </v-container>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import DocumentDrawer from '@/components/UI/document-drawer'

import Detail from './detail'

export default {
  components: { Detail, DocumentDrawer },
  data () {
    return {
      ddo: false,
      ddt: 'Class Society Details',
      cols: [
        {
          field: 'name',
          label: 'Name',
          width: '35%'
        },
        {
          field: 'value',
          label: 'Database Value',
          width: '15%'
        },
        {
          field: 'acb',
          label: 'Accredited Certification Body',
          width: '35%',
          valueFunction: item => {
            return item.acb.name
          }
        },
        {
          align: 'center',
          field: 'enabled',
          label: '',
          width: '1%',
          type: Boolean,
          icons: {
            false: {
              icon: 'mdi-close',
              color: 'error'
            },
            true: {
              icon: 'mdi-check-circle',
              color: 'success'
            }
          }
        }
      ],
      selected: null
    }
  },
  computed: {
  },
  methods: {
    select (item) {
      this.selected = item
      this.ddo = true
    }
  },
  setup(props, context) {
    const { ClassSociety } = context.root.$FeathersVuex.api

    const params = computed(() => {
      return {
        query: {
          $sort: { enabled: -1, name: 1 },
          $limit: 99
        },
      }
    })

    const { items  } = useFind({
      model: ClassSociety,
      params
    })

    return {
      items
    }
  }
}
</script>
