<template>
  <DataTable
    expandable
    :cols="cols"
    :data="items"
    @select="select"
  >
    <template
      v-slot:item.status="{ item }"
    >
      <v-icon
        v-for="(file, i) in item.uploads"
        v-text="'mdi-file'"
        small
        :color="file.metadata.accepted ? 'success' : file.metadata.rejected ? 'error lighten-1' : 'primary lighten-1'"
        :key="i"
      />
    </template>
    <template v-slot:item.expander="{ item }">
      <div class="ml-7 px-2 py-1">
        <v-row
          dense
          no-gutters
          v-if="item.message"
        >
          <v-col class="mb-3" style="white-space:pre-wrap">
            <v-sheet
              rounded
              color="white"
              class="d-flex align-center pa-4"
            >
              {{ item.message }}
            </v-sheet>
          </v-col>
        </v-row>
        <v-row
          dense
          no-gutters
          class="d-flex my-1"
        >
          <v-col
            v-for="(file, i) in item.uploads"
            cols="auto"
            class="mr-3 mb-2"
            :key="i"
          >
            <template
              v-if="file.metadata.accepted && file.metadata.accepted.date"
            >
              <v-btn
                class="text-none pa-5 pr-4 pl-3"
                depressed
                color="success lighten-1"
                outlined
                :min-height="60"
                @click="$emit('view', { item, index: i })"
              >
                <div class="d-flex">
                  <v-icon class="mr-2" large>mdi-file-check</v-icon>
                  <div class="d-flex flex-column align-start text-body-2">
                    <div class="success--text">{{ file.acbFindingFile }}</div>
                    <div class="grey--text text--darken-2">{{ itemStatus(file) }}</div>
                  </div>
                </div>
              </v-btn>
            </template>
            <template
              v-else-if="file.metadata.rejected && file.metadata.rejected.date"
            >
              <v-btn
                class="text-none pa-5 pr-4 pl-3"
                depressed
                color="error lighten-1"
                outlined
                :min-height="60"
                @click="$emit('view', { item, index: i })"
              >
                <div class="d-flex">
                  <v-icon class="mr-2" large>mdi-file-remove</v-icon>
                  <div class="d-flex flex-column align-start text-body-2">
                    <div class="error--text">{{ file.acbFindingFile }}</div>
                    <div class="grey--text text--darken-2">{{ itemStatus(file) }}</div>
                  </div>
                </div>
              </v-btn>
            </template>
            <template
              v-else
            >
              <v-btn
                class="text-none pa-5 pr-4 pl-3"
                depressed
                color="primary lighten-1"
                outlined
                :min-height="60"
                @click="$emit('view', { item, index: i })"
              >
                <div class="d-flex">
                  <v-icon class="mr-2" large>mdi-file</v-icon>
                  <div class="d-flex flex-column align-start text-body-2">
                    <div class="primary--text">{{ file.acbFindingFile }}</div>
                    <div class="grey--text text--darken-2">{{ itemStatus(file) }}</div>
                  </div>
                </div>
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </div>
    </template>
  </DataTable>
</template>

<style scoped>
.v-btn {
  background-color: white !important;
}
</style>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() { return {} }
    }
  },
  methods: {
    select(selection) {
      this.$emit('select', selection)
    }
  },
  computed: {
    cols () {
      return [
        {
          field: 'subject',
          label: 'Subject',
          width: '35%'
        },
        {
          field: 'message',
          label: 'Message',
          width: '45%',
          hideOnExpand: true,
          truncate: true,
          valueFunction: item => {
            return item.message
          }
        },
        {
          field: 'status',
          width: '5%',
          hideOnExpand: true
        }
      ]
    },
    itemStatus () {
      return file => {
        const metadata = file.metadata
        if (metadata.accepted && metadata.accepted.date) {
          const d = new Date(metadata.accepted.date).toIACSformat()
          return `Accepted - ${d}`
        }
        if (metadata.rejected && metadata.rejected.date) {
          const d = new Date(metadata.rejected.date).toIACSformat()
          return `Rejected - ${d}`
        }
        return 'Not actioned'
      }
    }
  }
}
</script>
