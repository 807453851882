<template>
  <v-container fluid>
    <v-row dense>
      <Menu
        v-model="menu"
        @select="select"
      />
      <v-col cols="" class="d-flex" style="position:relative">
        <div class="flex-grow-1 pl-2 ml-0">
          <Inbox v-if="menu === 0"/>
          <Rejected v-if="menu === 1"/>
          <History v-if="menu === 2"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import History from './tabs/message-history'
import Inbox from './tabs/inbox'
import Menu from './menu'
import Rejected from './tabs/rejected'

export default {
  components: {
    History,
    Inbox,
    Menu,
    Rejected
  },
  data () { return { menu: 0 } },
  methods: {
    select (value) { this.menu = value }
  }
}
</script>
