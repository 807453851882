<template>
  <v-container fluid>
    <DataTable
      :cols="cols"
      :data="items"
      @selectOne="selectOne"
    />
  </v-container>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

export default {
  props: {
    // items: {
    //   type: Array,
    //   default() { return [] }
    // }
  },
  data () {
    return {
      cols: [
        {
          field: 'userName',
          label: 'User',
          width: '10%',
          valueFunction: item => {
            return item.userName || item.detail.email
          }
        },
        {
          field: 'action',
          label: 'Action',
          width: '10%'
        },
        {
          field: 'detail',
          label: 'Detail',
          width: '10%',
          valueFunction: (item, field) => {
            const d = item[field]
            if (!d) return '-'
            return d.result
          }
        },
        {
          field: 'timestamp',
          label: 'Timestamp',
          width: '10%',
          valueFunction: (item, field) => {
            const d = item[field]
            if (!d) return '-'
            return new Date(d).toIACSformat(true)
          }
        }
      ]
    }
  },
  methods: {
    selectOne () {
    // selectOne (value) {
      // console.log(value)
    }
  },
  setup(props, context) {
    const { ActivityLog } = context.root.$FeathersVuex.api
    // const { $store } = context.root

    const params = computed(() => {
      return { query: { $sort: { '_id': -1 } } }
    })

    const fetchParams = computed(() => {
      return {
        query: {
          $select: [
            'userName',
            'action',
            'detail.result',
            'detail.email',
            'timestamp'
          ],
          $sort: { '_id': -1 },
          $limit: 99
        },
      }
    })
    const { items } = useFind({
      model: ActivityLog,
      params,
      fetchParams
    })
    return {
      items
    }
  }
}
</script>
