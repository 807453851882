<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <Confirm ref="confirm" />
    <RejectSelectedDialog
      ref="rejectSelectedDialog"
      :selected="file"
    />

    <PageSubtitle
      :icon="false"
      title="New Findings"
    />

    <v-col cols="12" class="mb-3">
      <List
        v-if="items.length"
        :items="items"
        @view="view"
      />
      <Message v-else />
    </v-col>

    <DocumentDrawer
      hide-print
      :open="documentDrawerOpen"
      :title="documentDrawerTitle"
      :width="960"
      :stateless="stateless"
      @close="documentDrawerOpen=false"
    >
      <template
        v-slot:actionMenu
      >
        <ActionMenu
          v-if="selected"
          :items="actionMenuItems"
        />
      </template>
      <template
        v-slot:default
      >
        <Submission
          v-if="selected.item._id"
          :id="selected.item._id"
          :index="selected.index"
          @next="next"
          @prev="prev"
        />
      </template>
    </DocumentDrawer>
  </v-row>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, reactive, ref } from '@vue/composition-api'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import PageSubtitle from '@/components/UI/page-subtitle'
import Submission from '../shared/submission'

import List from '../shared/submission-list'
import Message from './message'
import RejectSelectedDialog from './reject-dialog'

export default {
  components: {
    ActionMenu,
    DocumentDrawer,
    List,
    Message,
    PageSubtitle,
    RejectSelectedDialog,
    Submission
  },
  setup(props, context) {
    const { Submission } = context.root.$FeathersVuex.api

    const documentDrawerOpen = ref(false)
    const documentDrawerTitle = computed(() => {
      return selected.item.subject
    })

    const stateless = ref(false)
    const selected = reactive({ item: {}, index: null })

    const params = computed(() => {
      return { query: { read: false, $sort: { '_id': -1 } } }
    })
    const fetchParams = computed(() => {
      return {
        query: {
          read: false,
          $select: [
            'message',
            'subject',
            'read',
            'uploads._id',
            'uploads.acbFindingFile',
            'uploads.metadata.accepted.date',
            'uploads.metadata.rejected.date'
          ],
          $sort: { '_id': -1 },
          $limit: 99
        },
      }
    })

    const { items } = useFind({
      model: Submission,
      params,
      fetchParams
    })

    const file = computed(() => {
      if (selected.index === null) return null
      return selected.item.uploads[selected.index]
    })

    const actionMenuItems = computed(() => {
      if (selected.index === null) return []
      const metadata = file.value.metadata
      const a = {
          label: 'Accept and Import',
          icon: 'mdi-check',
          color: "success",
          action: accept,
          disabled: Boolean(metadata.accepted)
        }
      const r = {
          label: 'Reject',
          icon: 'mdi-close',
          color: "red",
          action: reject,
          disabled: Boolean(metadata.rejected)
        }
      const p = {
          label: 'Print',
          icon: 'mdi-printer',
          action: print
        }
      return (metadata.accepted || metadata.rejected)
        ? [p]
        : [a, r, null, p]
    })

    const view = ({ item, index }) => {
      selected.item = item
      selected.index = index
      documentDrawerOpen.value = true
    }

    const next = () => {
      selected.index += 1
    }

    const prev = () => {
      selected.index -= 1
    }

    // reference to the confirm dialogs
    const confirm = ref(null)
    const rejectSelectedDialog = ref(null)

    // handle the accept action.
    // throw an acceptance dialog
    const accept = async () => {
      stateless.value = true
      const result = await confirm.value.open(
        'Accept and Import this Finding?',
        `Are you sure you want to accept and import ${file.acbFindingFile} ?`,
        {
          acceptColor: 'primary',
          acceptLabel: 'Accept and Import',
          cancelLabel: 'Cancel',
          color: 'primary',
          icon: 'mdi-help-circle',
          width: 410
        }
      )
      if (result) {
        const field = `uploads.${selected.index}.metadata.accepted`
        const value = {
          date: Date.now(),
          browser: {
            platform: navigator.platform,
            language: navigator.language,
            userAgent: navigator.userAgent
          }
        }
        selected.item.patch({ data: { [field]: value }})
      }
      stateless.value = false
    }

    // handle the reject action.
    // throw an warning dialog with optional message
    const reject = async () => {
      stateless.value = true
      const { result, reason } = await rejectSelectedDialog.value.open()
      if (result) {
        const field = `uploads.${selected.index}.metadata.rejected`
        const value = {
          date: Date.now(),
          browser: {
              platform: navigator.platform,
              language: navigator.language,
              userAgent: navigator.userAgent
            },
          reason: reason
        }
        selected.item.patch({ data: { [field]: value }})
      }
      stateless.value = false
    }

    const print = () => {
      // console.log('print')
    }

    return {
      accept,
      actionMenuItems,
      confirm,
      documentDrawerOpen,
      documentDrawerTitle,
      file,
      items,
      next,
      prev,
      print,
      reject,
      rejectSelectedDialog,
      selected,
      stateless,
      view
    }
  }
}
</script>
