const enabled =  {
  align: 'center',
  field: 'enabled',
  label: '',
  width: '1%',
  type: Boolean,
  icons: {
    false: {
      icon: 'mdi-lock',
      color: 'error'
    },
    // true: {
    //   icon: 'mdi-check-circle',
    //   color: 'success'
    // }
  }
}
const role = {
  field: 'role',
  label: 'Role',
  width: '25%'
}

exports.tabs = [
{
  label: 'Op. Center',
  count: 44,
  type: 'oc',
  cols: [
    {
      field: 'name',
      label: 'Name',
      width: '25%'
    },
    role,
    {
      field: 'email',
      label: 'Email',
      width: '25%'
    },
    enabled
    // {
    //   field: 'telephone',
    //   label: 'Phone',
    //   width: '15%'
    // }
  ]
},
{
  label: 'Auditor',
  type: 'ac',
  cols: [
    {
      field: 'name',
      label: 'Name',
      width: '20%'
    },
    {
      field: 'assignedTo',
      label: 'Class Societies',
      width: '45%',
      valueFunction: item => {
        return item.assignedTo
          ? item.assignedTo.reduce((a, cs) => { a.push(cs.value); return a }, []).join(', ')
          : ''
      }
    },
    {
      field: 'email',
      label: 'Email',
      width: '20%'
    },
    {
      align: 'center',
      field: 'canCloseFindings',
      label: 'Can Close Findings',
      width: '15%',
      type: Boolean,
      icons: {
        true: {
          icon: 'mdi-check-bold',
          color: 'success'
        }
      }
    },
    enabled
  ]
},
{
  label: 'Class Society',
  type: 'cs',
  cols: [
    {
      field: 'name',
      label: 'Name',
      width: '25%'
    },
    {
      field: 'cs',
      label: 'Class Society',
      width: '25%',
      valueFunction: item => {
        return item.cs ? item.cs.name : ''
      }
    },
    role,
    {
      field: 'email',
      label: 'Email',
      width: '25%'
    },
    enabled
    // {
    //   field: 'telephone',
    //   label: 'Phone',
    //   width: '20%'
    // }
  ]
},
{
  label: 'Flag Admin.',
  type: 'fs',
  cols: [
    {
      field: 'name',
      label: 'Name',
      width: '25%'
    },
    {
      field: 'fs',
      label: 'Flag State',
      width: '25%',
      valueFunction: item => {
        return item.fs ? item.fs.name : ''
      }
    },
    role,
    {
      field: 'email',
      label: 'Email',
      width: '25%'
    },
    enabled
    // {
    //   field: 'note',
    //   label: 'Note',
    //   width: '40%',
    //   truncate: true
    // }
  ]
},
{
  label: 'Observer',
  type: 'ob',
  cols: [
    {
      field: 'name',
      label: 'Name',
      width: '25%'
    },
    role,
    {
      field: 'email',
      label: 'Email',
      width: '25%'
    },
    enabled
    // {
    //   field: 'note',
    //   label: 'Note',
    //   width: '45%',
    //   truncate: true
    // }
  ]
},
{
  label: 'Advanced Search',
  type: 'as',
  cols: [
    {
      field: 'name',
      label: 'Name',
      width: '25%'
    },
    role,
    {
      field: 'email',
      label: 'Email',
      width: '25%'
    },
    enabled
    // {
    //   field: 'note',
    //   label: 'Note',
    //   width: '45%',
    //   truncate: true
    // }
  ]
},

]
