<template>
  <v-container class="px-9 mt-4">
    <v-form
      ref="form"
    >
      <v-alert
        v-if="user._id && !user.enabled"
        type="error"
      >
        <b>This user account is disabled</b>
      </v-alert>

      <FormGroupInput
        field="name"
        hide-details="auto"
        :rules="['required']"
        :value="user.name"
        :readonly="Boolean(user._id)"
        :disabled="Boolean(user.type === 'ac' && user._id)"
        @change="change"
        @input="input"
      >
        Name
      </FormGroupInput>

      <FormGroupInput
        v-if="user.type !== 'ac'"
        field="role"
        :value="user.role"
        @change="change"
        @input="input"
      >
        Role
      </FormGroupInput>

      <v-divider class="my-5" />

      <FormGroupSectionLabel>Login Details</FormGroupSectionLabel>
      <FormGroupInput
        field="email"
        hide-details="auto"
        type="email"
        :rules="['required', 'email']"
        :value="user.email"
        @change="change"
        @input="input"
      >
        Email
      </FormGroupInput>

      <Password
        v-if="!user._id"
        :newUser="!user._id"
        @change="change"
        @update="change"
      />

      <v-divider class="my-5" />

      <template
        v-if="isUser('ac') && user._id"
      >
        <FormGroupLabelledComponent label="Assigned To">
          <ClassSocietyList
            :items="user.assignedTo"
            @add="addCS"
            @remove="removeCS"
          />
        </FormGroupLabelledComponent>
        <v-divider class="my-5" />
        <FormGroupSwitch
          :value="user.canCloseFindings"
          field="canCloseFindings"
          @change="change"
        >Can Close Findings</FormGroupSwitch>
        <v-divider class="my-5" />
      </template>

      <template
        v-if="isUser('cs')"
      >
        <ClassSocietySelector
          field="cs"
          :value="user.cs"
          :label="'Please Choose'"
          @change="changeCS"
        >Class Society</ClassSocietySelector>
        <v-divider class="my-5" />
      </template>

      <template
        v-if="isUser('fs')"
      >
        <FlagStateSelector
          field="fs"
          :value="user.fs"
          :label="'Please Choose'"
          @change="changeFS"
        >Flag State</FlagStateSelector>
        <v-divider class="my-5" />
      </template>

      <!-- <template
        v-if="user._id"
      >
        <FormGroupSectionLabel>Contact Information</FormGroupSectionLabel>
        <FormGroupInput
          field="telephone"
          :value="user.telephone"
          @change="change"
        >
          Telephone
        </FormGroupInput> -->
        <!-- <FormGroupInput
          field="timezone"
          :value="user.timezone"
          @change="change"
        >
          Timezone
        </FormGroupInput> -->
      <!-- </template> -->

      <v-row justify="end" class="mt-4">
        <v-col cols="auto">
          <v-btn
            v-if="!user._id"
            depressed
            color="success"
            :disabled="!canCreate"
            @click="create"
          >
            Save New User
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import FormGroupSwitch from '@/components/UI/forms/form-group/switch'
import FormGroupInput from '@/components/UI/forms/form-group/input'
import FormGroupLabelledComponent from '@/components/UI/forms/form-group/labelled-component'
import FormGroupSectionLabel from '@/components/UI/forms/form-group/section-label'

import ClassSocietyList from './class-society-list'
import ClassSocietySelector from './class-society-selector'
import FlagStateSelector from './flag-state-selector'
import Password from './password'

export default {
  props: {
    user: {
      type: Object,
      default () { return {} }
    }
  },
  components: {
    FormGroupSwitch,
    FormGroupInput,
    FormGroupLabelledComponent,
    FormGroupSectionLabel,
    ClassSocietyList,
    ClassSocietySelector,
    FlagStateSelector,
    Password,
  },
  // data () {
  //   return {
  //     rules: {
  //       email: value => Boolean(value.match(/@/)) || 'Not a valid email',
  //       required: value => !!value || 'Required.',
  //       min: value => value.length >= 8 || 'Minimum 8 characters',
  //       matchPassword: value => {
  //         return (value === this.user.password) || (`The passwords you entered do not match`)
  //       }
  //     }
  //   }
  // },
  computed: {
    isUser () {
      return type => this.user.type === type
    },
    canCreate () {
      const a = this.user.name
      const b = this.user.email
      const c = this.user.password
      const d = this.user.type === 'cs'
        ? this.user.cs && this.user.cs._id
        : true
      const e = this.user.type === 'fs'
        ? this.user.fs && this.user.fs._id
        : true
      return a && b && c && d && e
    }
  },
  methods: {
    change({ field, value }) {
      if (value instanceof String) {
        value = (value || '').trim()
      }
      // update on change / blur if this is an exisiting user
      if (this.user._id) {
        this.$store.dispatch('users/patch', [this.user._id, { [field]: value }])
      } else {
        this.user[field] = value
      }
    },
    input({ field, value }) {
      // update on input / keypress if this is an exisiting user
      if (!this.user._id) {
        if (value instanceof String) {
          value = (value || '').trim()
        }
        this.user[field] = value
      }
    },
    // add cs to assignedTo list
    addCS(id){
      const value = [...this.user.assignedTo, id]
      this.change({ field:'assignedTo', value })
    },
    // change cs for CS users
    changeCS({ field, value}) {
      this.change({ field, value })
    },
    // change fs for FS users
    changeFS({ field, value}) {
      this.change({ field, value })
    },
    // remove cs from assignedTo list
    removeCS(index){
      const value = [...this.user.assignedTo]
      value.splice(index, 1)
      this.change({ field:'assignedTo', value })
    },
    create () {
      this.user.create()
      // this.$store.dispatch('users/create', this.user)
      // this.$emit('close')
    }
  },
  watch: {
    user () {
      this.$refs.form.resetValidation()
    }
  }
  // setup(props, context) {
  //   const { ClassSociety } = context.root.$FeathersVuex.api


  //   const params = {
  //     query: {
  //       $select: ['name', '_id'],
  //       $sort: { name: 1 },
  //       $limit: 99
  //     }
  //   }

  //   const { items } = useFind({
  //     model: ClassSociety,
  //     params
  //   })

  //   const cslist = computed(() => {
  //     console.log('SETUP', cslist)
  //     return items.value.map(i => ({ label: i.name, value: i._id }))
  //   })

  //   return {
  //     cslist
  //   }
  // }

}
</script>
