<template>
  <v-container v-if="reject"
    class="px-6 mt-0"
    fluid
  >
    <v-row>
      <v-col>
        <v-alert
          class="font-weight-bold"
          dense
          text
          type="error"
          :icon="false"
        >
          Rejected by {{ rejected.user.name }} on {{ formatDate(rejected.date) }}
          <br />
          Reason: {{ rejected.reason }}
        </v-alert>
        <v-divider class="" />
      </v-col>
    </v-row>
    <!-- _{{ isPending }}_ -->
    <!-- <hr /> -->
    <v-row
      dense
      no-gutters
    >
      <v-col class="">
        <Finding
          :finding="finding"
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<style scoped>
  .v-btn{
    min-width: 38px !important;
    width: 38px !important;
  }
</style>

<script>
import { useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import Finding from "@/components/shared/findings/single-view"

export default {
  components: {
    Finding
  },
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
  },
  setup(props, context) {
    const { Rejected } = context.root.$FeathersVuex.api
    const id = computed(() => {
      return props.id
    })

    const { item: reject, isPending } = useGet({
      model: Rejected,
      id
    })

    const formatDate = d => {
      if (!d) return '-'
      return new Date(d).toIACSformat()
    }

    const finding = computed(() => reject.value.finding)
    const reason = computed(() => reject.value.reason)
    const rejected = computed(() => reject.value.metadata.rejected)

    return {
      finding,
      formatDate,
      isPending,
      reason,
      reject,
      rejected
    }
  }
}
</script>
