<template>
  <v-container class="px-9 mt-4">
    <v-form
      ref="form"
    >
      <FormGroupLabelledComponent
        align="center"
        label="Logo"
        :labelMarginTop="0"
      >
        <v-sheet
          color="#eee"
          class="pa-5"
          rounded
          width="200"
        >
          <v-img
            contain
            eager
            height="120"
            width="160"
            :src="`/img/class-societies/${item.logo}`"
          />
        </v-sheet>
      </FormGroupLabelledComponent>
      <FormGroupInput
        field="name"
        hide-details="auto"
        :rules="['required']"
        :value="item.name"
        :readonly="Boolean(item._id)"
        :disabled="Boolean(item._id)"
        @change="change"
        @input="input"
      >
        Name
      </FormGroupInput>
      <FormGroupInput
        field="value"
        hide-details="auto"
        :rules="['required']"
        :value="item.value"
        :readonly="Boolean(item._id)"
        :disabled="Boolean(item._id)"
        @change="change"
        @input="input"
      >
        Database value
      </FormGroupInput>
      <ACBSelector
        field="acb"
        :value="item.acb"
        :label="'Please Choose'"
        @change="change"
      />
      <FormGroupSwitch
        :value="item.enabled"
        field="enabled"
        @change="change"
      >
        Enabled
      </FormGroupSwitch>
    </v-form>
  </v-container>
</template>

<script>
import FormGroupInput from '@/components/UI/forms/form-group/input'
import FormGroupLabelledComponent from '@/components/UI/forms/form-group/labelled-component'
import FormGroupSwitch from '@/components/UI/forms/form-group/switch'

import ACBSelector from './acb-selector'

export default {
  props: {
    item: {
      type: Object,
      default () { return {} }
    }
  },
  components: {
    ACBSelector,
    FormGroupInput,
    FormGroupLabelledComponent,
    FormGroupSwitch
  },
  methods: {
    change({ field, value }) {
      if (value instanceof String) {
        value = (value || '').trim()
      }
      if (this.item._id) {
        this.$store.dispatch('class-societies/patch', [this.item._id, { [field]: value }])
      } else {
        this.item[field] = value
      }
    },
    input({ field, value }) {
      if (!this.item._id) {
        if (value instanceof String) {
          value = (value || '').trim()
        }
        this.user[field] = value
      }
    }
  }
}
</script>
