<template>
  <v-navigation-drawer
    v-model="visible"
    app
    clipped
    fixed
    disable-resize-watcher
    disable-route-watcher
    temporary
    style="-webkit-filter: blur(0)"
  >
    <div style="transform:translate3d(0px, 0px, 0px)">
      <template
        v-for="(section, key) in sections"
      >
        <div
          :key="key"
          class="mt-3"
        >
          <div
            :class="sectionLabelClass(section, 'text-subtitle-1 mb-2 ml-5')"
          >
            {{ key }}
          </div>
          <v-divider />
          <v-list
            dense
            _shaped
          >
            <v-list-item
              v-for="(item, j) in section"
              :key="j"
              :to="item.path"
              :ripple="false"
              :disabled="item.meta.menu.disabled"
              link
              exact
              @click="visible=false"
            >
              <v-list-item-icon class="mr-3">
                <!-- <v-icon :color="item.iconColor"> -->
                <v-icon
                  color="primary"
                  :disabled="item.meta.menu.disabled"
                >
                  {{ item.meta.menu.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.meta.menu.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { getMenuItems } from '@/router/oc'

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sectionLabelClass () {
      return (section, classes) => (['primary--text', classes].join(' '))
    },
    sections() {
      return getMenuItems()
    },
    visible: {
      get () {
        return this.open
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
