<template>
  <v-navigation-drawer
    permanent
    class="menu mt-n1 pt-1"
    width="200"
  >
    <v-list
      nav
      dense
    >
      <v-list-item-group
        v-model="index"
        color="primary"
        mandatory
        no-action
      >
        <v-list-item
          v-for="(item, i) in menuItems"
          class="mb-1"
          :key="i"
          :ripple="false"
        >
            <!-- <v-list-item-icon class="mx-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <div class="d-flex">
                <v-list-item-title class="pr-1">{{ item.label }}</v-list-item-title>
                <v-badge
                  bordered
                  class="font-weight-black"
                  inline
                  right
                  :color="item.countColor || 'red'"
                  :content="item.count"
                  :value="item.count"
                />
              </div>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped>
.menu{
  position: sticky !important;
  top:52px !important;
  /*overflow: visible !important;*/
  /*z-index: 1;*/
  /*transform-style: preserve-3d;*/
  will-change: all;
}
/*.menu:hover::after{
  content:' ';
  position: absolute;
  width: 10px;
  height: calc(100% - 20px);
  top:10px;
  right:4px;
  transform: translateZ(-1px);
  box-shadow: 5px 0px 12px 0px rgba(0,0,0,0.125);
  will-change: all;
}*/
.v-list-item{
  justify-content: flex-start !important;
}
</style>

<script>
// import { useFind } from 'feathers-vuex'
// import { computed } from '@vue/composition-api'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      ncount: 0,
      hcount: 0
    }
  },
  mounted () {
    this.$store.dispatch('submissions/fetchTotals')
    this.$store.dispatch('rejected/fetchTotals')
  },
  computed: {
    ...mapGetters('submissions', [ 'counts' ] ),
    ...mapGetters('rejected', [ 'count' ] ),
    index: {
      get: function(){
        return this.value
      },
      set: function(value){
        this.$emit('select', value)
      }
    },
    menuItems () {
      return [
        {
          icon: 'mdi-alert-decagram',
          label: 'New Findings',
          count: this.counts.unread || 0,
          countColor: 'light-green darken-1'
        },
        {
          icon: 'mdi-file-remove-outline',
          label: 'Rejected Findings',
          count: this.count || 0,
        },
        {
          icon: 'mdi-history',
          label: 'Message History',
          count: this.counts.imported || 0,
          countColor: 'orange darken-1'
        },
      ]
    }
  }
}
</script>
