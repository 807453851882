import Home from '@/pages/home'
import Menu from '@/pages/home/oc/menu'

import AdvancedSearch from '@/components/shared/advanced-search'
import Archive from '@/components/shared/archive'
import Inbox from '@/pages/home/oc/findings/inbox'
import Open from '@/components/shared/open-findings'
// import SavedSearches from '@/pages/home/oc/findings/saved-searches'

import AccreditedCertificationBodies from '@/pages/home/oc/manage/accredited-certification-bodies'
import FlagStates from '@/pages/home/oc/manage/flag-states'
import ClassSocieties from '@/pages/home/oc/manage/class-societies'
import ReportParameters from '@/pages/home/oc/manage/report-parameters'
import Users from '@/pages/home/oc/manage/users'

import UserActivityLog from '@/pages/home/oc/system-tools/user-activity-log'
import RecentChangesLog from '@/pages/home/oc/system-tools/recent-changes-log'

export const routes = [
  {
    props: true,
    component: Home,
    path: '*',
    children: [
      {
        component: Inbox,
        name: 'Inbox',
        path: '/findings/inbox',
        meta: {
          menu: {
            icon: 'mdi-message-text-outline',
            iconColor: 'grey darken-2',
            label: 'Inbox',
            section: 'ACB Findings',
          },
          page: {
            title: 'Inbox'
          }
        }
      },
      {
        component: Open,
        name: 'Open',
        path: '/findings/open',
        meta: {
          menu: {
            icon: 'mdi-list-status',
            iconColor: 'primary',
            label: 'Open Findings',
            section: 'ACB Findings',
          },
          page: {
            title: 'Open Findings'
          }
        }
      },
      {
        component: AdvancedSearch,
        name: 'AdvancedSearch',
        path: '/findings/advanced-search',
        meta: {
          menu: {
            icon: 'mdi-magnify',
            iconColor: 'deep-orange darken-1',
            label: 'Advanced Search',
            section: 'ACB Findings',
          },
          page: {
            title: 'Advanced Search'
          }
        }
      },
      {
        component: Archive,
        name: 'Archive',
        path: '/findings/archive',
        meta: {
          menu: {
            icon: 'mdi-archive',
            iconColor: 'orange darken-4',
            label: 'Archive',
            section: 'ACB Findings',
          },
          page: {
            title: 'Archive'
          }
        }
      },
      // {
      //   component: Import,
      //   name: 'Import',
      //   path: 'findings/import',
      //   meta: {
      //     menu: {
      //       section: 'ACB Findings',
            // icon: 'mdi-database-import',
      //       iconColor: 'light-blue accent-2',
      //       label: 'Import'
      //     },
      //     page: {
      //       title: 'Import'
      //     }
      //   }
      // },
      {
        component: Users,
        name: 'Users',
        path: '/manage/users',
        meta: {
          menu: {
            icon: 'mdi-account-box-multiple',
            // icon: 'mdi-account',
            iconColor: 'cyan darken-2',
            label: 'Users',
            section: 'Manage',
          },
          page: {
            title: 'Users'
          }
        }
      },
      {
        component: AccreditedCertificationBodies,
        name: 'AccreditedCertificationBodies',
        path: '/manage/accredited-certification-bodies',
        meta: {
          menu: {
            icon: 'mdi-bank',
            iconColor: 'cyan darken-2',
            label: 'ACBs',
            section: 'Manage',
          },
          page: {
            title: 'Accredited Certification Bodies'
          }
        }
      },
      {
        component: ClassSocieties,
        name: 'ClassSocieties',
        path: '/manage/class-societies',
        meta: {
          menu: {
            icon: 'mdi-ship-wheel',
            iconColor: 'cyan accent-4',
            label: 'Class Societies',
            section: 'Manage',
          },
          page: {
            title: 'Class Societies'
          }
        }
      },
      {
        component: FlagStates,
        name: 'FlagAdministrations',
        path: '/manage/flag-administrations',
        meta: {
          menu: {
            icon: 'mdi-flag-variant',
            iconColor: 'cyan accent-4',
            label: 'Flag Administrations',
            section: 'Manage',
          },
          page: {
            title: 'Flag Administrations'
          }
        }
      },
      {
        component: ReportParameters,
        name: 'ReportParameters',
        path: '/manage/report-parameters',
        meta: {
          menu: {
            disabled: true,
            icon: 'mdi-text-box-outline',
            iconColor: 'teal darken-4',
            label: 'Report Parameters',
            section: 'Manage',
          },
          page: {
            title: 'Report Parameters'
          }
        }
      },
      {
        component: UserActivityLog,
        name: 'UsersActivityLog',
        path: '/system-tools/user-activity-log',
        meta: {
          menu: {
            disabled: false,
            icon: 'mdi-account-clock',
            iconColor: 'deep-orange accent-4',
            label: 'Users Activity Log',
            section: 'System Tools'
          },
          page: {
            title: 'Users Activity Log'
          }
        }
      },
      {
        component: RecentChangesLog,
        name: 'RecentChangesLog',
        path: '/system-tools/recent-changes-log',
        meta: {
          menu: {
            disabled: true,
            icon: 'mdi-account-convert',
            iconColor: 'blue lighten-2',
            label: 'Recent Changes Log',
            section: 'System Tools',
          },
          page: {
            title: 'Recent Changes Log'
          }
        }
      },
      {
        component: Menu,
        name: 'Home',
        path: '/',
      }
    ]
  }
]

export function getMenuItems() {
  return routes[0].children.reduce((acc, value) => {
    if (!value.meta) return acc
    if (!acc[value.meta.menu.section]) {
      acc[value.meta.menu.section] = []
    }
    acc[value.meta.menu.section].push(value)
    return acc
  }, {})
}

export default { routes, getMenuItems }
